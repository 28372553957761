<template>
  <div class="fullscreen-player">
    <div class="fullscreen-player__wrapper">
      <Player
        :videos="videos"
        @end-video="endVideo"
        @close-video="closeVideo"
      />
      <div class="player-gradient" />
      <div class="player-transition" />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import Player from '@/components/Video/Partials/Player';

export default {
  name: 'FullscreenPlayer',
  components: {
    Player,
  },
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    endVideo() {
      this.nextStep();
    },
    closeVideo() {
      this.nextStep();
    },
    nextStep() {
      gsap.fromTo(
        '.player-transition',
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.8,
          ease: 'power3.out',
          onComplete: () => {
            this.$emit('completed');
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-player {
  ::v-deep video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: #000;
  }

  &__wrapper {
    position: absolute;
    top: 84px;
    left: 0;
    width: 100%;
    height: calc(100% - 84px);
    @include media-min($lg) {
      top: 95px;
      height: calc(100% - 95px);
    }
  }
}

.player-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 280px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}

.player-transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  pointer-events: none;
  opacity: 0;
  z-index: 5;
}
</style>
