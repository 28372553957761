<template>
  <div class="game-video">
    <FullscreenPlayer
      :videos="availableVideos"
      :is-loading="isLoading"
      @completed="completed"
    />
  </div>
</template>
<script>
import FullscreenPlayer from '@/components/Video/FullscreenPlayer';

export default {
  name: 'GameVideo',
  components: {
    FullscreenPlayer,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    availableVideos() {
      const videoMP4 = 'video/mp4';
      const isMiBrowser = /MiuiBrowser/i.test(navigator.userAgent);
      if (isMiBrowser) {
        return this.stage.videos().filter((video) => video.type === videoMP4);
      }

      return this.stage.videos();
    },
  },
  methods: {
    completed() {
      this.$emit('completed');
    },
  },
};
</script>
<style lang="scss" scoped>
.game-video {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
